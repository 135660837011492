import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const OurAgreementPage = () => {
  return (
    <Layout>
      <SEO title="Our Agreement" />

      <div className="flex flex-wrap mt-2 bg-white border border-gray-300">
        <div className="w-full p-4 md:p-10">
          <h1 className={`pb-4 border-b border-gray-300 mb-8`}>
            Our Agreement
          </h1>

          <p>
            Good business means all parties knowing what they're signing up to
            and our Lease Operations Managers will take you through the process
            and alert you to all key points, and you must also seek independent
            legal advice before signing any Lease agreement.
          </p>

          <h2>Standard Lease</h2>

          <p>Our standard lease consists of the following key terms:</p>

          <ul>
            <li>
              The lease runs for a 10-year term (although longer or shorter
              terms may be considered in exceptional circumstances)
            </li>
            <li>
              You are required to put the building in good repair &amp; keep it
              in that condition, as well as redecorating at regularly specified
              intervals
            </li>
            <li>
              You purchase the fixtures, fittings, stock-in-trade and glassware
            </li>
            <li>Rent is paid monthly and in advance</li>
            <li>There is an open market rent review every 5 years</li>
            <li>
              We take out Building Insurance on your behalf which we charge on
              to you and you are required to take out all other necessary
              business insurances
            </li>
            <li>
              You will be required to leave a deposit, usually £10,000, for the
              term of the lease unless otherwise agreed.
            </li>
          </ul>

          <h2>Capital Required</h2>

          <p>
            Every pub is different, particularly when it comes to the value of
            its inventory, but in broad terms the indicative capital required to
            take on a business is anticipated to be a minimum of £35,000. This
            figure does not include working capital, which you should include in
            your business plan.
          </p>

          <p>
            In addition to the deposit and the inventory value, indicative other
            costs to be considered will include the requirement to pay
            professional fees (e.g. your and our solicitors fees), stamp duty,
            and one month's rent in advance.&nbsp; Details of the capital
            requirement for your specific pub will be provided to you by your
            Lease Operations Manager at an early stage in the process.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default OurAgreementPage
